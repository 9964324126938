import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { LocalizationService } from '@abp/ng.core';
import { CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { Subscription } from 'rxjs/internal/Subscription';

import { DateTimeHelperService } from '@nexweb/shared/date-time-helper';
import { conditionalValidator, deepCopy } from '@nexweb/util';

import { RespondToActivationRequest } from '../../../models';

@Component({
  selector: 'nexweb-tso-form',
  templateUrl: './tso-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsoFormComponent implements OnInit, OnDestroy {
  @Input() response: RespondToActivationRequest.ActivationRequestResponse;
  @Input() labels: RespondToActivationRequest.ResponseLabels;
  @Input() dateTimeFormat: string;
  @Output() formValuesChanged: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() formValidationChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  responseFormGroup: FormGroup;
  responseFormGroupValueChange$: Subscription;
  lockCountDownConfig: CountdownConfig;
  maxResponseTimeReached: boolean;

  constructor(
    private localizationService: LocalizationService,
    private dateTimeHelperService: DateTimeHelperService,
  ) {}

  get requiredFieldError() {
    return this.localizationService.instant({
      key: 'AbpValidation::ThisFieldIsRequired',
      defaultValue: 'This field is required.',
    });
  }

  get minLengthError() {
    return this.localizationService.instant({
      key: 'AbpIdentity::MinFieldLength',
      defaultValue: 'A minimum of 50 characters is required',
    });
  }

  get maxLengthError() {
    return this.localizationService.instant({
      key: 'AbpIdentity::MaxFieldLength',
      defaultValue: 'A maximum of 200 characters is allowed',
    });
  }

  get showSubsequentInstrument() {
    return !!this.response.subsequentInstrument.instrument;
  }

  get currentReasonForDeclining() {
    return this.responseFormGroup.get('currentReasonForDeclining');
  }

  get currentActivationRequestAccepted() {
    return this.responseFormGroup.get('currentActivationRequestAccepted');
  }

  get subsequentReasonForDeclining() {
    return this.responseFormGroup.get('subsequentReasonForDeclining');
  }

  get subsequentActivationRequestAccepted() {
    return this.responseFormGroup.get('subsequentActivationRequestAccepted');
  }

  get currentInstrumentCssClass() {
    if (this.showSubsequentInstrument) {
      return 'col-md-6 col-sm-6';
    } else {
      return 'col-md-12 col-sm-12';
    }
  }

  ngOnInit(): void {
    this.initializeForm();

    this.initializeFormControlSubscription();

    this.maxResponseTimeReached = this.response.maxResponseTime == null;

    this.initializeCountDownTimer();

    this.toggleFormControlsEnabled();

    //emit default values
    this.formValuesChanged.emit({
      currentActivationRequestAccepted: true,
      currentReasonForDeclining: ' ',
      subsequentActivationRequestAccepted: true,
      subsequentReasonForDeclining: ' ',
      concurrencyStamp: this.response.concurrencyStamp,
    });
  }

  public onCurrentCheckboxChange(): void {
    if (!this.currentActivationRequestAccepted.value) {
      this.currentReasonForDeclining.enable();
    } else {
      this.currentReasonForDeclining.disable();
      this.currentReasonForDeclining.setValue('');
    }
  }

  public onSubsequentCheckboxChange(): void {
    if (!this.subsequentActivationRequestAccepted.value) {
      this.subsequentReasonForDeclining.enable();
    } else {
      this.subsequentReasonForDeclining.disable();
      this.subsequentReasonForDeclining.setValue('');
    }
  }

  public countdownEvent(event: CountdownEvent) {
    if (event.action === `done`) {
      this.maxResponseTimeReached = true;
      this.toggleFormControlsEnabled();
    }
  }

  ngOnDestroy(): void {
    if (this.responseFormGroupValueChange$) {
      this.responseFormGroupValueChange$.unsubscribe();
    }
  }

  private initializeForm(): void {
    this.responseFormGroup = new FormGroup({
      currentInstrument: new FormControl({
        value: this.response.currentInstrument.instrument,
        disabled: true,
      }),
      currentRequestingTsoPortfolio: new FormControl({
        value: this.response.currentInstrument.requestingTsoPortfolio,
        disabled: true,
      }),
      currentSupplyingBspPortfolio: new FormControl({
        value: this.response.currentInstrument.supplyingBspPortfolio,
        disabled: true,
      }),
      currentRequestedQuantity: new FormControl({
        value: this.response.currentInstrument.requestedQuantity,
        disabled: true,
      }),
      currentDefaultFullDispatchTime: new FormControl({
        value: this.response.currentInstrument.defaultFullDispatchTime,
        disabled: true,
      }),
      currentEarlyActivationRequested: new FormControl({
        value: this.response.currentInstrument.earlyActivationRequested,
        disabled: true,
      }),
      currentRequestedFullDispatchTime: new FormControl({
        value: this.response.currentInstrument.requestedFullDispatchTime,
        disabled: true,
      }),
      currentConfirmedFullDispatchTime: new FormControl({
        value: this.response.currentInstrument.confirmedFullDispatchTime,
        disabled: true,
      }),
      currentActivationRequestAccepted: new FormControl({
        value: true,
        disabled: this.maxResponseTimeReached,
      }),
      currentReasonForDeclining: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [
          conditionalValidator(
            () => !this.responseFormGroup.get('currentActivationRequestAccepted').value,
            Validators.compose([Validators.required, Validators.minLength(50), Validators.maxLength(200)]),
          ),
        ],
      ),
      subsequentInstrument: new FormControl({
        value: this.response.subsequentInstrument.instrument,
        disabled: true,
      }),
      subsequentRequestingTsoPortfolio: new FormControl({
        value: this.response.subsequentInstrument.requestingTsoPortfolio,
        disabled: true,
      }),
      subsequentSupplyingBspPortfolio: new FormControl({
        value: this.response.subsequentInstrument.supplyingBspPortfolio,
        disabled: true,
      }),
      subsequentRequestedQuantity: new FormControl({
        value: this.response.subsequentInstrument.requestedQuantity,
        disabled: true,
      }),
      subsequentDefaultFullDispatchTime: new FormControl({
        value: this.response.subsequentInstrument.defaultFullDispatchTime,
        disabled: true,
      }),
      subsequentEarlyActivationRequested: new FormControl({
        value: false,
        disabled: true,
      }),
      subsequentRequestedFullDispatchTime: new FormControl({
        value: new Date(),
        disabled: true,
      }),
      subsequentConfirmedFullDispatchTime: new FormControl({
        value: this.response.subsequentInstrument.confirmedFullDispatchTime,
        disabled: true,
      }),
      subsequentActivationRequestAccepted: new FormControl({
        value: true,
        disable: this.maxResponseTimeReached,
      }),
      subsequentReasonForDeclining: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [
          conditionalValidator(
            () => !this.responseFormGroup.get('subsequentActivationRequestAccepted').value,
            Validators.compose([Validators.required, Validators.minLength(50), Validators.maxLength(200)]),
          ),
        ],
      ),
    });
  }

  private initializeFormControlSubscription() {
    this.responseFormGroupValueChange$ = this.responseFormGroup.valueChanges.subscribe((value: unknown) => {
      this.formValuesChanged.emit(value);
      this.formValidationChanged.emit(this.responseFormGroup.valid);
    });
  }

  private getMaxResponseTimeAsSeconds(maxResponseTime: Date): number {
    const maxResponseDateTime = deepCopy(maxResponseTime);

    const currentDateTime = this.dateTimeHelperService.getServerTzDateTime();

    const diff = maxResponseDateTime.getTime() - currentDateTime.getTime();

    const secondsRemaining = Math.abs(diff / 1000);

    return secondsRemaining;
  }

  private initializeCountDownTimer(): void {
    if (!this.maxResponseTimeReached) {
      this.lockCountDownConfig = {
        leftTime: this.getMaxResponseTimeAsSeconds(this.response.maxResponseTime),
      };
    }
  }

  private toggleFormControlsEnabled(): void {
    if (this.maxResponseTimeReached) {
      this.responseFormGroup.disable();
    } else {
      this.currentActivationRequestAccepted.enable();
      if (this.currentActivationRequestAccepted.value) {
        this.currentReasonForDeclining.disable();
      } else {
        this.currentReasonForDeclining.enable();
      }
      this.subsequentActivationRequestAccepted.enable();
      if (this.subsequentActivationRequestAccepted.value) {
        this.subsequentReasonForDeclining.disable();
      } else {
        this.subsequentReasonForDeclining.enable();
      }
    }
  }
}
