<div class="row">
  <div class="col-md-12">
    <countdown
      class="right-float h3 float-right"
      *ngIf="lockCountDownConfig"
      #cd
      [config]="lockCountDownConfig"
      (event)="countdownEvent($event)"></countdown>
  </div>
</div>
<form [formGroup]="responseFormGroup">
  <div class="form-group input-group">
    <div class="{{ currentInstrumentCssClass }}">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.currentInstrument }}">
        <input
          kendoTextBox
          formControlName="currentInstrument"
          class="k-input-full"
          [value]="response.currentInstrument.instrument" />
      </kendo-floatinglabel>
    </div>
    <div *ngIf="showSubsequentInstrument" class="col-md-6 col-sm-6">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.subsequentInstrument }}">
        <input
          kendoTextBox
          formControlName="subsequentInstrument"
          class="k-input-full"
          [value]="response.subsequentInstrument.instrument" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="{{ currentInstrumentCssClass }}">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.requestingTsoPortfolio }}">
        <input
          kendoTextBox
          formControlName="currentRequestingTsoPortfolio"
          class="k-input-full"
          [value]="response.currentInstrument.requestingTsoPortfolio" />
      </kendo-floatinglabel>
    </div>
    <div *ngIf="showSubsequentInstrument">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.requestingTsoPortfolio }}">
        <input
          kendoTextBox
          formControlName="subsequentRequestingTsoPortfolio"
          class="k-input-full"
          [value]="response.subsequentInstrument.requestingTsoPortfolio" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="{{ currentInstrumentCssClass }}">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.supplyingBspPortfolio }}">
        <input
          kendoTextBox
          formControlName="currentSupplyingBspPortfolio"
          class="k-input-full"
          [value]="response.currentInstrument.supplyingBspPortfolio" />
      </kendo-floatinglabel>
    </div>
    <div *ngIf="showSubsequentInstrument" class="col-md-6 col-sm-6">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.supplyingBspPortfolio }}">
        <input
          kendoTextBox
          formControlName="subsequentSupplyingBspPortfolio"
          class="k-input-full"
          [value]="response.subsequentInstrument.supplyingBspPortfolio" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="{{ currentInstrumentCssClass }}">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.requestedQuantity }}">
        <input
          kendoTextBox
          formControlName="currentRequestedQuantity"
          class="k-input-full"
          [value]="response.currentInstrument.requestedQuantity" />
      </kendo-floatinglabel>
    </div>
    <div *ngIf="showSubsequentInstrument" class="col-md-6 col-sm-6">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.requestedQuantity }}">
        <input
          kendoTextBox
          formControlName="subsequentRequestedQuantity"
          class="k-input-full"
          [value]="response.subsequentInstrument.requestedQuantity" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="{{ currentInstrumentCssClass }}">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.defaultFullDispatchTime }}">
        <kendo-datetimepicker
          formControlName="currentDefaultFullDispatchTime"
          [format]="dateTimeFormat"
          class="k-input-full"
          [value]="response.currentInstrument.defaultFullDispatchTime"></kendo-datetimepicker>
      </kendo-floatinglabel>
    </div>
    <div *ngIf="showSubsequentInstrument" class="col-md-6 col-sm-6">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.defaultFullDispatchTime }}">
        <kendo-datetimepicker
          formControlName="subsequentDefaultFullDispatchTime"
          [format]="dateTimeFormat"
          class="k-input-full"
          [value]="response.subsequentInstrument.defaultFullDispatchTime"></kendo-datetimepicker>
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="{{ currentInstrumentCssClass }}">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.earlyActivationRequested }}">
        <input
          type="checkbox"
          kendoCheckBox
          formControlName="currentEarlyActivationRequested"
          [value]="response.currentInstrument.earlyActivationRequested" />
      </kendo-floatinglabel>
    </div>
    <div *ngIf="showSubsequentInstrument" class="col-md-6 col-sm-6">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.earlyActivationRequested }}">
        <kendo-label text="">
          <input type="hidden" formControlName="subsequentEarlyActivationRequested" class="k-input-full" [value]="" />
        </kendo-label>
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="{{ currentInstrumentCssClass }}">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.requestedFullDispatchTime }}">
        <kendo-datetimepicker
          formControlName="currentRequestedFullDispatchTime"
          [format]="dateTimeFormat"
          class="k-input-full"
          [value]="response.currentInstrument.requestedFullDispatchTime"></kendo-datetimepicker>
      </kendo-floatinglabel>
    </div>
    <div *ngIf="showSubsequentInstrument" class="col-md-6 col-sm-6">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.requestedFullDispatchTime }}">
        <kendo-label text="">
          <input type="hidden" formControlName="subsequentRequestedFullDispatchTime" class="k-input-full" [value]="" />
        </kendo-label>
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="{{ currentInstrumentCssClass }}">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.confirmedFullDispatchTime }}">
        <kendo-datetimepicker
          formControlName="currentConfirmedFullDispatchTime"
          [format]="dateTimeFormat"
          class="k-input-full"
          [value]="response.currentInstrument.confirmedFullDispatchTime"></kendo-datetimepicker>
      </kendo-floatinglabel>
    </div>
    <div *ngIf="showSubsequentInstrument" class="col-md-6 col-sm-6">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.confirmedFullDispatchTime }}">
        <kendo-datetimepicker
          formControlName="subsequentConfirmedFullDispatchTime"
          [format]="dateTimeFormat"
          class="k-input-full"
          [value]="response.subsequentInstrument.confirmedFullDispatchTime"></kendo-datetimepicker>
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="{{ currentInstrumentCssClass }}">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.activationRequestAccepted }}">
        <input
          type="checkbox"
          kendoCheckBox
          formControlName="currentActivationRequestAccepted"
          [value]="response.currentInstrument.activationRequestAccepted"
          (change)="onCurrentCheckboxChange()" />
      </kendo-floatinglabel>
    </div>
    <div *ngIf="showSubsequentInstrument" class="col-md-6 col-sm-6">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.activationRequestAccepted }}">
        <input
          type="checkbox"
          kendoCheckBox
          formControlName="subsequentActivationRequestAccepted"
          [value]="response.currentInstrument.activationRequestAccepted"
          (change)="onSubsequentCheckboxChange()" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="{{ currentInstrumentCssClass }}">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.reasonForDeclining }}">
        <textarea
          kendoTextArea
          formControlName="currentReasonForDeclining"
          rows="4"
          [autoSize]="true"
          class="k-input-full"
          [value]="response.currentInstrument.reasonForDeclining"></textarea>
        <div
          *ngIf="currentReasonForDeclining.errors && currentReasonForDeclining.errors['required']"
          class="error-message">
          {{ requiredFieldError }}
        </div>
        <div
          *ngIf="currentReasonForDeclining.errors && currentReasonForDeclining.errors['minlength']"
          class="error-message">
          {{ minLengthError }}
        </div>
        <div
          *ngIf="currentReasonForDeclining.errors && currentReasonForDeclining.errors['maxlength']"
          class="error-message">
          {{ maxLengthError }}
        </div>
      </kendo-floatinglabel>
    </div>
    <div *ngIf="showSubsequentInstrument">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.reasonForDeclining }}">
        <textarea
          kendoTextArea
          formControlName="subsequentReasonForDeclining"
          rows="4"
          [autoSize]="true"
          class="k-input-full"
          [value]="response.subsequentInstrument.reasonForDeclining"></textarea>
        <div
          *ngIf="subsequentReasonForDeclining.errors && subsequentReasonForDeclining.errors.required"
          class="error-message">
          {{ requiredFieldError }}
        </div>
        <div
          *ngIf="subsequentReasonForDeclining.errors && subsequentReasonForDeclining.errors.minlength"
          class="error-message">
          {{ minLengthError }}
        </div>
        <div
          *ngIf="subsequentReasonForDeclining.errors && subsequentReasonForDeclining.errors.maxlength"
          class="error-message">
          {{ maxLengthError }}
        </div>
      </kendo-floatinglabel>
    </div>
  </div>
</form>
