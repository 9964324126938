import { Inject, Injectable } from '@angular/core';

import { OAuthService } from 'angular-oauth2-oidc';

import { NotificationTypeEnum } from '@nexweb/shared/data-access/mdd';
import { BaseSignalR, SignalRHubNames } from '@nexweb/shared/signalr-helper';
import { API_URL_TOKEN } from '@nexweb/shared/tokens/api-url';

import { BmDialogService } from '../services/bm-dialog.service';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';

@Injectable({
  providedIn: 'root',
})
export class BmSystemMessagingHubService extends BaseSignalR {
  constructor(
    @Inject(API_URL_TOKEN) apiUrl: string,
    private oAuthService: OAuthService,
    private bmDialogService: BmDialogService,
  ) {
    super(apiUrl);
  }

  public initialize(): void {
    this.stopConnection();
    this.buildConnection(SignalRHubNames.systemMessaging, this.oAuthService.getAccessToken());
    this.startConnection();
    this.initEvents();
  }

  protected initEvents(): void {
    this.hubConnection.on(
      'NotifyUser',
      (
        messageId: number,
        messageText: string,
        notificationComponent: NotificationTypeEnum,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        parameters: { ErrorMessage: string; BidId: number },
      ) => {
        switch (notificationComponent) {
          case NotificationTypeEnum.requestingTsoConfirmation:
            this.bmDialogService.showRequestingTsoConfirmationDialog(parameters.BidId);
            break;
          case NotificationTypeEnum.supplyingTsoNotification:
            this.bmDialogService.showSupplyingTsoNotificationDialog(parameters.BidId).subscribe((result) => {
              if (
                !(result instanceof DialogCloseResult) &&
                !(result.text === this.bmDialogService.getSupplyingTsoSubmitButtonText())
              ) {
                this.bmDialogService.showTsoResponseToActivationRequestDialog(parameters.BidId);
              }
            });
            break;
          case NotificationTypeEnum.supplyingBspNotification:
            this.bmDialogService.showSupplyingBspNotificationDialog(parameters.BidId);
            break;
          case NotificationTypeEnum.acceptanceOfActivationRequest:
            this.bmDialogService.showAcceptanceOfActivationRequestNotificationDialog(parameters.BidId);
            break;
          case NotificationTypeEnum.declinationOfActivationRequest:
            this.bmDialogService.showDeclinationOfActivationRequestNotificationDialog(parameters.BidId);
            break;
          case NotificationTypeEnum.deactivationInstructingTsoConfirmation:
            this.bmDialogService.showDeactivationInstructingTsoConfirmationDialog(parameters.BidId);
            break;
          case NotificationTypeEnum.deactivationSupplyingTsoNotification:
            this.bmDialogService.showDeactivationSupplyingTsoNotificationDialog(parameters.BidId);
            break;
          case NotificationTypeEnum.deactivationSupplyingBspNotification:
            this.bmDialogService.showDeactivationSupplyingBspNotificationDialog(parameters.BidId);
            break;
          case NotificationTypeEnum.requestingTsoConfirmationOfExtension:
            this.bmDialogService.showExtensionRequestingTsoConfirmationDialog(parameters.BidId);
            break;
          case NotificationTypeEnum.supplyingTsoNotificationOfExtension:
            this.bmDialogService.showExtensionSupplyingTsoNotificationDialog(parameters.BidId).subscribe((result) => {
              if (
                !(result instanceof DialogCloseResult) &&
                !(result.text === this.bmDialogService.getSubmitButtonText())
              ) {
                this.bmDialogService.showTsoResponseToExtensionRequestDialog(parameters.BidId);
              }
            });
            break;
          case NotificationTypeEnum.supplyingBspNotificationOfExtension:
            this.bmDialogService.showExtensionSupplyingBspNotificationDialog(parameters.BidId);
            break;
          default:
            break;
        }
      },
    );
  }
}
