import { Injectable } from '@angular/core';

import { LocalizationService } from '@abp/ng.core';
import { DialogCloseResult, DialogRef, DialogResult, DialogService } from '@progress/kendo-angular-dialog';
import { DialogAction } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { AudioSounds, playSoundDecorator } from '@nexweb/shared/audio-helper';
import { AcceptRejectActivation, BmDataAccessFacade } from '@nexweb/shared/data-access/bm';

import {
  DeactivationInstructingTsoConfirmationComponent,
  DeactivationSupplyingBspNotificationComponent,
  RequestingTsoConfirmationComponent,
  SupplyingBspNotificationComponent,
  ExtReqTsoConfComponent,
  ExtSupBspNotComponent,
  ExtSupTsoNotComponent,
  ExtTsoResponseComponent,
  AcceptanceComponent,
  DeclinationComponent,
  TsoComponent,
} from '@nexweb/shared/bm-system-messaging-components';

@Injectable({
  providedIn: 'root',
})
export class BmDialogService {
  constructor(
    private localizationService: LocalizationService,
    private dialogService: DialogService,
    private bmFacade: BmDataAccessFacade,
  ) {}

  @playSoundDecorator(AudioSounds.notification)
  public showDeactivationInstructingTsoConfirmationDialog(bidId: number): Observable<DialogResult> {
    const dialogRef: DialogRef = this.dialogService.open({
      title: this.getDeactivationConfirmationTitle(),
      content: DeactivationInstructingTsoConfirmationComponent,
      width: 500,
      actions: [{ text: this.getSubmitButtonText(), primary: 'true' }],
      actionsLayout: 'end',
    });

    const instructingTsoConfirmation = dialogRef.content.instance;

    instructingTsoConfirmation.bidId = bidId;
    instructingTsoConfirmation.mustAcknowledgeMessageRead = true;

    return dialogRef.result;
  }

  @playSoundDecorator(AudioSounds.notification)
  public showDeactivationSupplyingTsoNotificationDialog(bidId: number): Observable<DialogResult> {
    const dialogRef: DialogRef = this.dialogService.open({
      title: this.getSupplyingTsoDeactivationNotificationTitle(),
      content: DeactivationSupplyingBspNotificationComponent,
      width: 500,
      actions: [{ text: this.getSubmitButtonText(), primary: 'true' }],
      actionsLayout: 'end',
    });

    const supplyingTsoNotification = dialogRef.content.instance;

    supplyingTsoNotification.bidId = bidId;
    supplyingTsoNotification.mustAcknowledgeMessageRead = true;

    return dialogRef.result;
  }

  @playSoundDecorator(AudioSounds.notification)
  public showDeactivationSupplyingBspNotificationDialog(bidId: number): Observable<DialogResult> {
    const dialogRef: DialogRef = this.dialogService.open({
      title: this.getSupplyingBspDeactivationNotificationTitle(),
      content: DeactivationSupplyingBspNotificationComponent,
      width: 500,
      actions: [{ text: this.getSubmitButtonText(), primary: 'true' }],
      actionsLayout: 'end',
    });

    const supplyingBspNotification = dialogRef.content.instance;

    supplyingBspNotification.bidId = bidId;
    supplyingBspNotification.mustAcknowledgeMessageRead = true;

    return dialogRef.result;
  }

  @playSoundDecorator(AudioSounds.notification)
  public showTsoResponseToActivationRequestDialog(bidId: number): void {
    const dialogRef: DialogRef = this.dialogService.open({
      title: this.getTsoResponseTitle(),
      content: TsoComponent,
      width: 500,
      actions: [{ text: this.getTsoResponseSubmitButtonText(), primary: 'true' }],
      actionsLayout: 'end',
      preventAction: (ev: DialogResult, dialog?: DialogRef) => {
        if (ev instanceof DialogAction && ev.text) {
          return !dialog?.content?.instance.isFormValid && ev.text === this.getTsoResponseSubmitButtonText();
        } else {
          return false;
        }
      },
    });

    const tsoResponseToActivationRequest = dialogRef.content.instance;

    tsoResponseToActivationRequest.bidId = bidId;

    dialogRef.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult)) {
        const acceptRejectActivation = {
          bidId: bidId,
          accepted: tsoResponseToActivationRequest.accepted,
          subsequentBidAccepted: tsoResponseToActivationRequest.subsequentBidAccepted,
          concurrencyStamp: tsoResponseToActivationRequest.concurrencyStamp,
          confirmedFullDispatchTime: tsoResponseToActivationRequest.confirmedFullDispatchTime,
          rejectReason: tsoResponseToActivationRequest.rejectReason,
          subsequentRejectReason: tsoResponseToActivationRequest.subsequentRejectReason,
        } as AcceptRejectActivation;

        this.bmFacade.acceptRejectActivation(acceptRejectActivation).pipe(first()).subscribe();
      }
    });
  }

  @playSoundDecorator(AudioSounds.notification)
  public showAcceptanceOfActivationRequestNotificationDialog(bidId: number): Observable<DialogResult> {
    const dialogRef: DialogRef = this.dialogService.open({
      title: this.getAcceptanceTitle(),
      content: AcceptanceComponent,
      width: 500,
      actions: [{ text: this.getSubmitButtonText(), primary: 'true' }],
      actionsLayout: 'end',
    });

    const acceptanceNotification = dialogRef.content.instance;

    acceptanceNotification.bidId = bidId;

    return dialogRef.result;
  }

  @playSoundDecorator(AudioSounds.notification)
  public showDeclinationOfActivationRequestNotificationDialog(bidId: number): Observable<DialogResult> {
    const dialogRef: DialogRef = this.dialogService.open({
      title: this.getDeclinationTitle(),
      content: DeclinationComponent,
      width: 500,
      actions: [{ text: this.getSubmitButtonText(), primary: 'true' }],
      actionsLayout: 'end',
    });

    const declinationNotification = dialogRef.content.instance;

    declinationNotification.bidId = bidId;

    return dialogRef.result;
  }

  @playSoundDecorator(AudioSounds.notification)
  public showSupplyingBspNotificationDialog(bidId: number): Observable<DialogResult> {
    const dialogRef: DialogRef = this.dialogService.open({
      title: this.getSupplyingBspActivationRequestNotificationTitle(),
      content: SupplyingBspNotificationComponent,
      width: 500,
      actions: [{ text: this.getSubmitButtonText(), primary: 'true' }],
      actionsLayout: 'end',
    });

    const supplyingBspNotification = dialogRef.content.instance;

    supplyingBspNotification.bidId = bidId;
    supplyingBspNotification.mustAcknowledgeMessageRead = true;

    return dialogRef.result;
  }

  @playSoundDecorator(AudioSounds.notification)
  public showSupplyingTsoNotificationDialog(bidId: number): Observable<DialogResult> {
    const dialogRef: DialogRef = this.dialogService.open({
      title: this.getSupplyingTsoActivationRequestNotificationTitle(),
      content: SupplyingBspNotificationComponent,
      width: 500,
      actions: [
        { text: this.getSupplyingTsoSubmitButtonText() },
        {
          text: this.getActivationRequestNotificationSubmitButtonText(),
          primary: 'true',
        },
      ],
      actionsLayout: 'end',
    });

    const supplyingTsoNotification = dialogRef.content.instance;

    supplyingTsoNotification.bidId = bidId;
    supplyingTsoNotification.mustAcknowledgeMessageRead = true;

    return dialogRef.result;
  }

  @playSoundDecorator(AudioSounds.notification)
  public showRequestingTsoConfirmationDialog(bidId: number): Observable<DialogResult> {
    const dialogRef: DialogRef = this.dialogService.open({
      title: this.getActivationRequestConfirmationTitle(),
      content: RequestingTsoConfirmationComponent,
      width: 500,
      actions: [{ text: this.getSubmitButtonText(), primary: 'true' }],
      actionsLayout: 'end',
    });

    const requestingTsoNotification = dialogRef.content.instance;

    requestingTsoNotification.bidId = bidId;
    requestingTsoNotification.mustAcknowledgeMessageRead = true;

    return dialogRef.result;
  }

  @playSoundDecorator(AudioSounds.notification)
  public showExtensionSupplyingBspNotificationDialog(bidId: number): Observable<DialogResult> {
    const dialogRef: DialogRef = this.dialogService.open({
      title: this.getExtensionSupplyingBspNotificationTitle(),
      content: ExtSupBspNotComponent,
      width: 500,
      actions: [{ text: this.getSubmitButtonText(), primary: 'true' }],
      actionsLayout: 'end',
    });

    const supplyingBspNotification = dialogRef.content.instance;

    supplyingBspNotification.bidId = bidId;
    supplyingBspNotification.mustAcknowledgeMessageRead = true;

    return dialogRef.result;
  }

  @playSoundDecorator(AudioSounds.notification)
  public showExtensionSupplyingTsoNotificationDialog(bidId: number): Observable<DialogResult> {
    const dialogRef: DialogRef = this.dialogService.open({
      title: this.getExtensionSupplyingTsoNotificationTitle(),
      content: ExtSupTsoNotComponent,
      width: 500,
      actions: [
        { text: this.getSubmitButtonText() },
        {
          text: this.getExtensionNotificationSubmitButtonText(),
          primary: 'true',
        },
      ],
      actionsLayout: 'end',
    });

    const supplyingTsoNotification = dialogRef.content.instance;

    supplyingTsoNotification.bidId = bidId;
    supplyingTsoNotification.mustAcknowledgeMessageRead = true;

    return dialogRef.result;
  }

  @playSoundDecorator(AudioSounds.notification)
  public showExtensionRequestingTsoConfirmationDialog(bidId: number): Observable<DialogResult> {
    const dialogRef: DialogRef = this.dialogService.open({
      title: this.getExtensionConfirmationTitle(),
      content: ExtReqTsoConfComponent,
      width: 500,
      actions: [{ text: this.getSubmitButtonText(), primary: 'true' }],
      actionsLayout: 'end',
    });

    const requestingTsoConfirmation = dialogRef.content.instance;

    requestingTsoConfirmation.bidId = bidId;
    requestingTsoConfirmation.mustAcknowledgeMessageRead = true;

    return dialogRef.result;
  }

  @playSoundDecorator(AudioSounds.notification)
  public showTsoResponseToExtensionRequestDialog(bidId: number): void {
    const dialogRef: DialogRef = this.dialogService.open({
      title: this.getTsoResponseTitle(),
      content: ExtTsoResponseComponent,
      width: 500,
      actions: [{ text: this.getTsoResponseSubmitButtonText(), primary: 'true' }],
      actionsLayout: 'end',
      preventAction: (ev: DialogResult, dialog?: DialogRef) => !dialog?.content?.instance.isFormValid,
    });

    const tsoResponseToExtensionRequest = dialogRef.content.instance;

    tsoResponseToExtensionRequest.bidId = bidId;

    dialogRef.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult)) {
        const acceptRejectActivation = {
          bidId: bidId,
          accepted: tsoResponseToExtensionRequest.accepted,
          subsequentBidAccepted: tsoResponseToExtensionRequest.subsequentBidAccepted,
          concurrencyStamp: tsoResponseToExtensionRequest.concurrencyStamp,
          confirmedFullDispatchTime: tsoResponseToExtensionRequest.confirmedFullDispatchTime,
          rejectReason: tsoResponseToExtensionRequest.rejectReason,
          subsequentRejectReason: tsoResponseToExtensionRequest.subsequentRejectReason,
        } as AcceptRejectActivation;

        this.bmFacade.acceptRejectActivation(acceptRejectActivation).pipe(first()).subscribe();
      }
    });
  }

  public getSubmitButtonText(): string {
    return this.localizationService.instant({
      key: 'Bm::SubmitButtonText',
      defaultValue: '',
    });
  }

  public getSupplyingTsoSubmitButtonText(): string {
    return this.localizationService.instant({
      key: 'Bm::SupplyingTsoSubmitButtonText',
      defaultValue: '',
    });
  }

  private getSupplyingBspDeactivationNotificationTitle(): string {
    return this.localizationService.instant({
      key: 'Bm::SupplyingBspIssueDeactivationInstructionDialogTitleNotification',
      defaultValue: 'Supplying BSP Notification of Deactivation Instruction',
    });
  }

  private getSupplyingTsoDeactivationNotificationTitle(): string {
    return this.localizationService.instant({
      key: 'Bm::SupplyingTsoIssueDeactivationInstructionDialogTitleNotification',
      defaultValue: 'Supplying TSO Notification of Deactivation Instruction',
    });
  }

  private getDeactivationConfirmationTitle(): string {
    return this.localizationService.instant({
      key: 'Bm::IssueDeactivationInstructionDialogTitleConfirmation',
      defaultValue: 'Instructing TSO Confirmation of Deactivation Instruction Issued',
    });
  }

  private getAcceptanceTitle(): string {
    return this.localizationService.instant({
      key: 'Bm::RespondToActivationRequestDialogTitleAccepted',
      defaultValue: 'Notification: Acceptance of Activation Request',
    });
  }

  private getDeclinationTitle(): string {
    return this.localizationService.instant({
      key: 'Bm::RespondToActivationRequestDialogTitleDeclined',
      defaultValue: 'Notification: Declination of Activation Request',
    });
  }

  private getTsoResponseTitle(): string {
    return this.localizationService.instant({
      key: 'Bm::RequestActivationDialogTitleNotification',
      defaultValue: 'Notification of Activation Request Received',
    });
  }

  private getTsoResponseSubmitButtonText(): string {
    return this.localizationService.instant({
      key: 'Bm::RespondToActivationRequestButtonTextTsoResponse',
      defaultValue: 'Submit',
    });
  }

  private getSupplyingTsoActivationRequestNotificationTitle(): string {
    return this.localizationService.instant({
      key: 'Bm::RequestActivationDialogTitleNotification',
      defaultValue: 'Supplying TSO Notification of Activation Request Received',
    });
  }

  private getSupplyingBspActivationRequestNotificationTitle(): string {
    return this.localizationService.instant({
      key: 'Bm::SupplyingTsoRequestActivationDialogTitleNotification',
      defaultValue: 'Supplying BSP Notification of Activation Request Received',
    });
  }

  private getActivationRequestConfirmationTitle(): string {
    return this.localizationService.instant({
      key: 'Bm::RequestActivationDialogTitleConfirmation',
      defaultValue: '',
    });
  }

  private getActivationRequestNotificationSubmitButtonText(): string {
    return this.localizationService.instant({
      key: 'Bm::RequestActivationButtonTextSupplyingTsoNotification',
      defaultValue: '',
    });
  }

  private getExtensionNotificationSubmitButtonText(): string {
    return this.localizationService.instant({
      key: 'Bm::ExtensionRequestButtonTextSupplyingTsoNotification',
      defaultValue: 'Respond To Activation Extension Request',
    });
  }

  private getExtensionSupplyingTsoNotificationTitle(): string {
    return this.localizationService.instant({
      key: 'Bm::SupplyingTsoExtensionRequestDialogTitleNotification',
      defaultValue: 'Supplying TSO Notification of Activation Extension Request',
    });
  }

  private getExtensionSupplyingBspNotificationTitle(): string {
    return this.localizationService.instant({
      key: 'Bm::SupplyingBspExtensionRequestDialogTitleNotification',
      defaultValue: 'Supplying BSP Notification of Activation Extension Request',
    });
  }

  private getExtensionConfirmationTitle(): string {
    return this.localizationService.instant({
      key: 'Bm::ExtensionRequestDialogTitleConfirmation',
      defaultValue: 'Requesting TSO Confirmation of Extension Request',
    });
  }
}
